import React from "react";

// @mui material components
import { Grid, Avatar, Card, Box, Typography, Stack, Divider, Chip } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import typography from "assets/theme/base/typography";

// icons
// import ApartmentTwoToneIcon from "@mui/icons-material/ApartmentTwoTone";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";

import { stringToColor } from "utilities";

function JobCard({deadline, detailsHandler, companyName, logo, category, title, jobType, location }) {
  const { size } = typography;
  return (
    <Card
      sx={{
        p: 0.8,
        borderRadius: "10px",
        border: "1px solid #E9EEF4",
        boxShadow: "none",
        width: "100%",
      }}
    >
      <Card
        sx={{
          mb: 2,
          p: 2,
          borderRadius: "8px",
          // border: "1px solid #E9EEF4",
          boxShadow: "none",
          background: '#017992',
        }}
      >
        <Stack direction="column" spacing={1}>
          <Stack direction="row" justifyContent="space-between">
            <Chip
              sx={{ fontWeight: "bold", background: "white", height: "100%", py: 0.5 }}
              label={deadline}
            />
            <Box>saved</Box>
          </Stack>
          <Typography variant="subtitle2" sx={{ fontSize: size.sm }}>
            {companyName}
          </Typography>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4" style={{ color: "black" }}>
              {title}
            </Typography>
            <Avatar alt="Remy Sharp" src={logo} sx={{ width: 30, height: 30 }} />
          </Stack>
          <Stack direction="row" spacing={1}>
            <Chip variant="outlined" sx={{ p: 0 }} label={jobType} />
          </Stack>
        </Stack>
      </Card>
      <Stack sx={{ px: 2 }} spacing={2} direction="row" justifyContent="space-between">
        <Stack direction="column">
          <Typography variant="subtitle2">
            <b>{title}</b>
          </Typography>
          <Typography variant="subtitle2" sx={{ fontSize: size.sm, color: "#7b809a" }}>
            {location}
          </Typography>
        </Stack>

        <Chip onClick={detailsHandler} variant="outlined" sx={{ p: 0 }} label="Details" />
      </Stack>
    </Card>
  );
}

export default JobCard;
