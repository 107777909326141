/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react imports
import React, { useEffect, useState } from "react";

// @mui material components
import { Grid, Card, Chip, Box, Typography, Stack, Divider } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import typography from "assets/theme/base/typography";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data

import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";

import LoadingComponent from "components/LoadingComponent";
// react-router-dom components
import { useNavigate } from "react-router-dom";

// icons
import ApartmentTwoToneIcon from "@mui/icons-material/ApartmentTwoTone";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import SchoolIcon from "@mui/icons-material/School";
// data
import userTableData from "components/UserTableData";

// API
import { pullStatsApi } from "services";
import { generalPostApi, getStatsApi } from "../../services";
import HorizontalBarChart from "../../examples/Charts/BarCharts/HorizontalBarChart";
import StatisticsCard from "components/StatisticsCard";

import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  Legend,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import LatestJobCard from "components/LatestJobCard";
import ProfileProgressCard from "components/ProfileProgressCard";
import JobCard from "components/JobCard";
import { fDate } from "utilities";


function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const { size } = typography;
  const userData = JSON.parse(sessionStorage.getItem("userData"));

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState([{}]);
  const [latestJobs, setLatestJobs] = useState([]);
  const { rows, columns, loadingTable } = userTableData();

  const pullStatsHandler = async () => {
    setLoading(true);
    try {
      const {
        data: { code, ...rest },
      } = await generalPostApi({
        code: 111,
        api: 100,
        data: { user_id: userData.user_id },
      });
      if (code === 200) {
        var k  = (rest.data.complete/4) *100
        setLoading(false);
        setStats({profile_complete:k,... rest});
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const pullJobsHandler = async () => {
    setLoading(true);
    try {
      const {
        data: { code, jobs, msg },
      } = await generalPostApi({
        code: 112,
        api: 100,
        data: { user_id: userData.user_id },
      });
      if (code === 200) {
        setLoading(false);
        setLatestJobs(jobs);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const validateAdmin = (userId) => {
    if (userId === undefined || userId == null) {
      navigate("authentication/sign-in");
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    validateAdmin(userData) ? pullStatsHandler() : navigate("/authentication/sign-in");
    pullJobsHandler();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box sx={{ minHeight:'100vh', background: "white", my: 2 ,py:2}}>
        <Card sx={{ boxShadow: "none", px: 2, py: 2, borderRadius: "5px" }}>
          <Grid container spacing={1}>
            <Grid sx={{ display: "flex" }} item xs={12} md={6}>
              <ProfileProgressCard percentage={ stats?.profile_complete} />
            </Grid>

            <Grid sx={{ display: "flex" }} item xs={6} md={2}>
              <StatisticsCard
                stat={stats?.jobs ?? "0"}
                title="Jobs"
                bg="#CFF2D1"
                icon={<StoreOutlinedIcon color="success" />}
              />
            </Grid>

            <Grid sx={{ display: "flex" }} item xs={6} md={2}>
              <StatisticsCard
                bg="#EBD7D6"
                stat={1}
                title="Courses"
                icon={<SchoolIcon color="success" />}
              />
            </Grid>

            <Grid sx={{ display: "flex" }} item xs={6} md={2}>
              <StatisticsCard
                bg="#00C49F"
                stat={stats?.interview ?? "0"}
                title="Interview"
                icon={<SchoolIcon color="success" />}
              />
            </Grid>
          </Grid>
        </Card>
        <Grid container spacing={2} sx={{ mt: 2, px: 2 }}>
          <Grid item sm={12} md={12}>
            <Stack spacing={2} direction="row" alignItems="center">
              <Typography sx={{ mb: 1 }} variant="h4">
                <b>Recommended for you </b>
              </Typography>
              <Chip variant="outlined" sx={{ height: "100%", px: 0 }} label={latestJobs.length} />
            </Stack>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              {latestJobs.length > 0
                ? latestJobs.map((item) => {
                    return (
                      <Grid style={{ width: "100%" }} item sx={12} md={4}>
                        <JobCard
                        deadline={fDate(item.deadline)}
                          category={item.category.name}
                          jobType={item.job_type}
                          location={item.location}
                          title={item.title}
                          logo={item.company.logo_url}
                          companyName={"Invict"}
                          detailsHandler={() => navigate("/jobProfile/" + item.job_id)}
                        />
                      </Grid>
                    );
                  })
                : null}
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
