import React, { useState, useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import LoadingButton from "@mui/lab/LoadingButton";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

// Notistack
import { useSnackbar } from "notistack";

// react-router-dom components
import { useNavigate } from "react-router-dom";
import { generalPostApi } from "services";

function Cover() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({});

  // functions

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (details.password !== details.conf_password) {
      enqueueSnackbar("Password do no match!", { variant: "error" });
      setLoading(false);
      return;
    }
    try {
      const {
        data: { code, msg },
      } = await generalPostApi({
        code: 100,
        api: 100,
        data: details,
      });
      if (code === 200) {
        setLoading(false);
        enqueueSnackbar("Successfully signup");
        navigate("/authentication/sign-in");
      } else {
        enqueueSnackbar(msg, { variant: "error" });
        setLoading(false);
      }
    } catch (error) {
      console.log("ERR", error);
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      setLoading(false);
    }
  };

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <form onSubmit={submitHandler}>
            <MDBox mb={2}>
              <MDInput
                required
                onChange={handleChange}
                name="full_name"
                type="text"
                label="Name"
                variant="standard"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                required
                onChange={handleChange}
                name="email"
                type="email"
                label="Email"
                variant="standard"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                required
                name="password"
                onChange={handleChange}
                type="password"
                label="Password"
                variant="standard"
                fullWidth
              />
            </MDBox>

            <MDBox mb={2}>
              <MDInput
                required
                name="conf_password"
                onChange={handleChange}
                type="password"
                label="Confirm Password"
                variant="standard"
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <LoadingButton
                type="submit"
                style={{ color: "white" }}
                loading={loading}
                variant="contained"
              >
                signin
              </LoadingButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </form>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
