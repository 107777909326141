import React from "react";

// @mui material components
import { CircularProgress, Grid, Card, Box, Typography, Stack, Divider } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import typography from "assets/theme/base/typography";

// icons
// import ApartmentTwoToneIcon from "@mui/icons-material/ApartmentTwoTone";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";

function ProfileProgressCard({ percentage }) {
  const { size } = typography;
  return (
    <Stack
      sx={{ borderRadius: "10px", p: 1, width: "100%", background: "#017992" }}
      alignItems="center"
      spacing={2}
      direction="row"
    >
      <Box sx={{ position: "relative", display: "flex", alignItems: "center" }}>
        <CircularProgress
          style={{ color: "white" }}
          size={40}
          variant="determinate"
          value={percentage}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            style={{ fontSize: size.xs, display: "flex", alignItems: "center", color: "white" }}
            variant="h4"
          >
            {percentage}%
          </Typography>
        </Box>
      </Box>

      <Typography style={{ color: "white" }}>Complete your profile</Typography>
    </Stack>
  );
}

export default ProfileProgressCard;
