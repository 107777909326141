import React, { useRef, useEffect } from "react";

import YouTube from "react-youtube";

import "./videocardStyles.css";
// @mui material components
import { Grid, Avatar, Card, Box, Typography, Stack, Divider, Chip } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import typography from "assets/theme/base/typography";
import { generalPostApi } from "services";

function VideoCard({ src, course }) {
  const userData = JSON.parse(sessionStorage.getItem("userData"));

  const { size } = typography;
  const urlArray = src.split("/");
  console.log(urlArray);
  const iframeRef = useRef(null);

  const extractVideoId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);

    if (match && match[2].length === 11) {
      return match[2];
    } else {
      // Handle invalid URL or ID not found
      return null;
    }
  };

  const onEndHandler = async () => {
    try {
      const {
        data: { code, ...rest },
      } = await generalPostApi({
        code: 130,
        api: 100,
        data: { user_id: userData.user_id, user_course_id: course.user_course_id },
      });
      if (code === 200) {
        console.log("LOGGED");
      } else {
        console.log("NOT LOGGED");
      }
    } catch (error) {
      console.log("NOT LOGGED", error);
    }
  };

  return (
    <Card>
      <YouTube
        opts={{
          width: "100%",
          height: "425px",
          borderRadius: "10px",
        }}
        onEnd={onEndHandler}
        videoId={extractVideoId(src)}
      />
      {/* <iframe
        ref={iframeRef}
        style={{ borderRadius: "10PX" }}
        width="100%"
        height="425px"
        src={`https://www.youtube.com/embed/${urlArray[3]}`}
        allowfullscreen
      ></iframe> */}
    </Card>
  );
}

export default VideoCard;
