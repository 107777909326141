/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react imports
import React, { useEffect, useState } from "react";

// @mui material components
import {
  Grid,
  Card,
  Chip,
  Box,
  Input,
  InputAdornment,
  Typography,
  Stack,
  Divider,
} from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import typography from "assets/theme/base/typography";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data

import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";

import LoadingComponent from "components/LoadingComponent";
// react-router-dom components
import { useNavigate } from "react-router-dom";

// icons
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import SchoolIcon from "@mui/icons-material/School";
import SearchIcon from "@mui/icons-material/Search";
import TodayIcon from "@mui/icons-material/Today";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
// import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import TrackChangesIcon from "@mui/icons-material/TrackChanges";

// data
import userTableData from "components/UserTableData";

// API
import { generalPostApi, getStatsApi } from "../../services";

import StatisticsCard from "components/StatisticsCard";

import ProfileProgressCard from "components/ProfileProgressCard";

import CourseCard from "components/CourseCard";
import VideoCard from "components/VideoCard";

//images
import sadIllustration from "../../assets/images/sadIllustration.png";

// Notistack
import { useSnackbar } from "notistack";

function Courses() {
  const { sales, tasks } = reportsLineChartData;
  const { size } = typography;
  const userData = JSON.parse(sessionStorage.getItem("userData"));

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState([{}]);
  const [latestJobs, setLatestJobs] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);

  const pullStatsHandler = async () => {
    setLoading(true);
    try {
      const {
        data: { code, ...rest },
      } = await generalPostApi({
        code: 111,
        api: 100,
        data: { user_id: "68252d1ed3094378afffd6ee276cde43" },
      });
      if (code === 200) {
        setLoading(false);
        setStats(rest);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const pullJobsHandler = async () => {
    setLoading(true);
    try {
      const {
        data: { code, courses, msg },
      } = await generalPostApi({
        code: 129,
        api: 100,
        data: { user_id: userData.user_id },
      });
      if (code === 200) {
        setLoading(false);
        setLatestJobs(courses);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const enrollHandler = async () => {
    setLoading(true);
    try {
      const {
        data: { code, courses, msg },
      } = await generalPostApi({
        code: 117,
        api: 100,
        data: { user_id: userData.user_id, course_id: selectedCourse.course_id },
      });
      if (code === 200) {
        setLoading(false);
        enqueueSnackbar("Successfully Enrolled!", { variant: "success" });
      } else {
        setLoading(false);
        enqueueSnackbar("Something went wring");
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar("Something went wring");
    }
  };

  const validateAdmin = (userId) => {
    if (userId === undefined || userId == null) {
      navigate("authentication/sign-in");
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    validateAdmin(userData) ? pullStatsHandler() : navigate("/authentication/sign-in");
    pullJobsHandler();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box sx={{ borderRadius: 2, background: "white", my: 2, pb: 2 }}>
        <Card sx={{ boxShadow: "none", px: 2, py: 2, borderRadius: "5px" }}>
          <Grid container spacing={1}>
            <Grid sx={{ display: "flex" }} item xs={12} md={6}>
              <ProfileProgressCard percentage={34} />
            </Grid>

            <Grid sx={{ display: "flex" }} item xs={6} md={2}>
              <StatisticsCard
                stat={stats?.jobs ?? "0"}
                title="Jobs"
                bg="#CFF2D1"
                icon={<StoreOutlinedIcon color="success" />}
              />
            </Grid>

            <Grid sx={{ display: "flex" }} item xs={6} md={2}>
              <StatisticsCard
                bg="#EBD7D6"
                stat={1}
                title="Courses"
                icon={<SchoolIcon color="success" />}
              />
            </Grid>

            <Grid sx={{ display: "flex" }} item xs={6} md={2}>
              <StatisticsCard
                bg="#00C49F"
                stat={stats?.interview ?? "0"}
                title="Interview"
                icon={<SchoolIcon color="success" />}
              />
            </Grid>
          </Grid>
        </Card>
        <Grid container spacing={2} sx={{ mt: 2, px: 2 }}>
          <Grid item sm={12} md={4}>
            <Stack sx={{ pb: 2 }} spacing={2} direction="row" justifyContent="space-between">
              <Typography sx={{ mb: 1 }} variant="h4">
                <b>Courses </b>
              </Typography>
              <Input
                sx={{
                  mb: 5,
                  // backgroundColor: "#F3F3F3",
                  borderRadius: "5px",
                  padding: "0px 10px 0px 10px",
                  border: "2px solid #E9EEF4",
                }}
                placeholder="Search"
                disableUnderline
                id="input-with-icon-adornment"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </Stack>
            <Grid
              // sx={{height:'100vh', overflow:'scroll'}}
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {latestJobs.length > 0
                ? latestJobs.map((item) => {
                    return (
                      <Grid style={{ width: "100%" }} item sx={12} md={12}>
                        <CourseCard
                          isSelected={
                            selectedCourse?.user_course_id == item.user_course_id ? true : false
                          }
                          id={item.course.course_id}
                          category={{ icon_url: "asdf" }}
                          jobType={item?.course.course_name}
                          description={item?.course.description}
                          title={item.course.course_name}
                          logo={item?.course.course_name}
                          companyName={"Invict"}
                          viewHandler={() => setSelectedCourse(item)}
                        />
                      </Grid>
                    );
                  })
                : null}
            </Grid>
          </Grid>

          <Grid item sm={12} md={8}>
            <Card sx={{ p: 2, height: "100%" }}>
              {selectedCourse ? (
                <Stack spacing={2} direction="column">
                  <VideoCard course={selectedCourse} src={selectedCourse.course.course_url} />
                  <Stack spacing={5} direction="row">
                    <Stack spacing={1} direction="row" alignItems="center">
                      <TodayIcon />
                      <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                        {selectedCourse.registered}
                      </Typography>
                    </Stack>
                    <Stack spacing={1} alignItems="center" direction="row">
                      <SellOutlinedIcon sx={{ color: "#7b809a" }} />
                      <Typography sx={{ color: "#7b809a", fontSize: size.sm }} variant="subtitle2">
                        {selectedCourse.category}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Typography variant="h4">
                    <b>{selectedCourse.course_name}</b>
                  </Typography>

                  <Typography sx={{ fontSize: size.sm, color: "#7b809a" }} variant="subtitle2">
                    {selectedCourse.description}
                  </Typography>

                  <Stack direction="column">
                    <Typography variant="subtitle2">
                      <b>skills</b>
                    </Typography>
                    <Typography sx={{ color: "#7b809a" }} variant="subtitle2">
                      {selectedCourse.skills}
                    </Typography>
                  </Stack>

                  <Stack direction="column">
                    <TrackChangesIcon sx={{ color: "black" }} />
                    <Typography sx={{ color: "#7b809a", fontSize: size.sm }} variant="subtitle2">
                      {selectedCourse.objective}
                    </Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="flex-end">
                    <Chip color="warning" onClick={enrollHandler} label="ENROLL" />
                  </Stack>
                </Stack>
              ) : (
                <Stack direction="column" justifyContent="center">
                  <Typography variant="h4">NO COURSE SELECTED</Typography>

                  <img
                    style={{ width: "100%", height: "auto", objectFit: "cover" }}
                    src={sadIllustration}
                  />
                </Stack>
              )}
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Footer />
    </DashboardLayout>
  );
}

export default Courses;
