/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react imports
import React, { useEffect, useState } from "react";


import PropTypes from "prop-types";

// @mui material components
import {
  Grid,
  Card,
  Chip,
  Box,
  Input,
  InputAdornment,
  Typography,
  Stack,
  Divider,
  Tab, Tabs,
} from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import typography from "assets/theme/base/typography";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data

import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";

import LoadingComponent from "components/LoadingComponent";
// react-router-dom components
import { useNavigate } from "react-router-dom";

// icons
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import SchoolIcon from "@mui/icons-material/School";
import SearchIcon from "@mui/icons-material/Search";
import TodayIcon from "@mui/icons-material/Today";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
// import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';

// data
import userTableData from "components/UserTableData";

// API
import { generalPostApi, getStatsApi } from "../../services";

import StatisticsCard from "components/StatisticsCard";

import ProfileProgressCard from "components/ProfileProgressCard";

import CourseCard from "components/CourseCard";
import VideoCard from "components/VideoCard";
import JobCard from "components/JobCard";

//images 
import sadIllustration from "../../assets/images/sadIllustration.png"

// Notistack
import { useSnackbar } from "notistack";
import { fDateTime } from "utilities";



function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }


function Applications() {
  const { sales, tasks } = reportsLineChartData;
  const { size } = typography;
  const userData = JSON.parse(sessionStorage.getItem("userData"));

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();


  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState([{}]);
  const [latestJobs, setLatestJobs] = useState([]);
  const [ courses,setCourses]=useState([]);
  const [selectedCourse,setSelectedCourse] = useState(null)

  
  const pullStatsHandler = async () => {
    setLoading(true);
    try {
      const {
        data: { code, ...rest },
      } = await generalPostApi({
        code: 111,
        api: 100,
        data: { user_id: userData.user_id },
      });
      if (code === 200) {
        setLoading(false);
        setStats(rest);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const pullJobsHandler = async () => {
    setLoading(true);
    try {
      const {
        data: { code, applications, msg },
      } = await generalPostApi({
        code: 123,
        api: 100,
        data: { user_id: userData.user_id },
      });
      if (code === 200) {
        setLoading(false);
        setLatestJobs(applications);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const pullCoursesHandler = async () => {
    setLoading(true);
    try {
      const {
        data: { code, courses, msg },
      } = await generalPostApi({
        code: 129,
        api: 100,
        data: { user_id: userData.user_id },
      });
      if (code === 200) {
        setLoading(false);
        setCourses(courses);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };


  
  const enrollHandler = async () => {
    setLoading(true);
    try {
      const {
        data: { code, courses, msg },
      } = await generalPostApi({
        code: 117,
        api: 100,
        data: { user_id: userData.user_id, course_id:selectedCourse.course_id },
      });
      if (code === 200) {
        setLoading(false);
        enqueueSnackbar("Successfully Enrolled!", { variant: "success" });

       
      } else {
        setLoading(false);
        enqueueSnackbar("Something went wring") 
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar("Something went wring") 
    }
  };

  const validateAdmin = (userId) => {
    if (userId === undefined || userId == null) {
      navigate("authentication/sign-in");
      return false;
    } else {
      return true;
    }
  };



  useEffect(() => {
    validateAdmin(userData) ? pullStatsHandler() : navigate("/authentication/sign-in");
    pullJobsHandler();
    pullCoursesHandler();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box sx={{ borderRadius:2, background: "white", my: 2, pb:2 }}>
        <Card sx={{ boxShadow: "none", px: 2, py: 2, borderRadius: "5px" }}>
          <Grid container spacing={1}>
            <Grid sx={{ display: "flex" }} item xs={12} md={6}>
              <ProfileProgressCard percentage={34} />
            </Grid>

            <Grid sx={{ display: "flex" }} item xs={6} md={2}>
              <StatisticsCard
                stat={stats?.jobs ?? "0"}
                title="Jobs"
                bg="#CFF2D1"
                icon={<StoreOutlinedIcon color="success" />}
              />
            </Grid>

            <Grid sx={{ display: "flex" }} item xs={6} md={2}>
              <StatisticsCard
                bg="#EBD7D6"
                stat={1}
                title="Courses"
                icon={<SchoolIcon color="success" />}
              />
            </Grid>

            <Grid sx={{ display: "flex" }} item xs={6} md={2}>
              <StatisticsCard
                bg="#00C49F"
                stat={stats?.interview ?? "0"}
                title="Interview"
                icon={<SchoolIcon color="success" />}
              />
            </Grid>
          </Grid>
        </Card>
        <Grid item xs={12} md={12}>
            <Box
              sx={(theme) => ({
                width: "100%",
                [theme.breakpoints.up("md")]: {
                  px: 3,
                },
              })}
            >
              <Box>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab
                    label={
                      <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                        Jobs
                      </Typography>
                    }
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={
                      <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                        Courses
                      </Typography>
                    }
                    {...a11yProps(1)}
                  />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Box sx={{ width:'100%', borderRadius: "5px", p: 2, background: "#f0f2f5" }}>
                    <Grid container rowSpacing={1} columnSpacing={{ p:2,height:'100%', xs: 1, sm: 2, md: 3 }}>
                        {latestJobs.length > 0
                            ? latestJobs.map((item) => {
                                return (
                                <Grid style={{ width: "100%" }} item sx={12} md={4}>
                                    <JobCard
                                        deadline={fDateTime(item.registered)}
                                        category="Category"
                                        jobType={item.job.job_type}
                                        location={item.job.location}
                                        title={item.job.title}
                                        logo={item.company.logo_url}
                                        companyName={item.company.name}
                                        detailsHandler={() => navigate("/jobProfile/" + item.job.job_id)}
                                    />
                                </Grid>
                                );
                            })
                            : <Stack direction="column"  justifyContent="center">
                            <Typography variant="h4">
                                NO APPLICATIONS
                            </Typography>

                            <img style={{ width:'100%',height:'auto', objectFit: "cover" }} src={sadIllustration} />
                            
                            </Stack>}
                    </Grid>
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Box sx={{ borderRadius: "5px", p: 2, background: "#f0f2f5" }}>
                    <Grid container spacing={2} sx={{ mt: 2, px: 2 }}>
                        <Grid item sm={12} md={4}>
                            <Stack sx={{pb:2}} spacing={2} direction="row" justifyContent="space-between">
                            <Typography sx={{ mb: 1 }} variant="h4">
                                <b>Courses </b>
                            </Typography>
                            <Input
                                sx={{
                                mb: 5,
                                // backgroundColor: "#F3F3F3",
                                borderRadius: "5px",
                                padding: "0px 10px 0px 10px",
                                border: "2px solid #E9EEF4",
                                }}
                                placeholder="Search"
                                disableUnderline
                                id="input-with-icon-adornment"
                                startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                                }
                            />
                            </Stack>
                            <Grid 
                            // sx={{height:'100vh', overflow:'scroll'}} 
                            container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            {courses.length > 0
                                ? courses.map((item) => {
                                  var item = item.course;
                                    return (
                                    <Grid style={{ width: "100%" }} item sx={12} md={12}>
                                        <CourseCard
                                        isSelected={selectedCourse?.course_id==item.course_id?true:false}
                                        id={item.course_id}
                                        category={{icon_url:"asdf"}}
                                        jobType={item?.course_name}
                                        description={item?.description}
                                        title={item.course_name}
                                        logo={item?.course_name}
                                        companyName={"Invict"}
                                        viewHandler={() =>setSelectedCourse(item)}
                                        />
                                    </Grid>
                                    );
                                })
                                : null}
                            </Grid>
                        </Grid>

                        <Grid item sm={12} md={8}>
                        <Card sx={{ p:2,height:'100%'}} >
                            {selectedCourse?
                            <Stack spacing={2} direction="column">
                            <VideoCard src={selectedCourse.course_url} /> 
                            <Stack spacing={5} direction="row">
                                <Stack spacing={1} direction="row"  alignItems="center" >
                                <TodayIcon />
                                <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                                    {selectedCourse.registered}
                                </Typography>
                                </Stack>
                                <Stack spacing={1} alignItems="center" direction="row">
                                <SellOutlinedIcon sx={{ color: "#7b809a" }} />
                                <Typography sx={{ color: "#7b809a", fontSize: size.sm }} variant="subtitle2">
                                    {selectedCourse.category}
                                </Typography>
                                </Stack>
                            </Stack>
                            <Typography variant="h4">
                                <b>{selectedCourse.course_name}</b>
                            </Typography>

                            <Typography sx={{fontSize: size.sm,color: "#7b809a"}} variant="subtitle2">
                                {selectedCourse.description}
                            </Typography>

                            <Stack direction="column">
                                <Typography   variant="subtitle2">
                                <b>skills</b>
                                </Typography>
                                <Typography  sx={{ color: "#7b809a"}}  variant="subtitle2">
                                {selectedCourse.skills}
                                </Typography>
                            </Stack>

                            <Stack  direction="column">
                                <TrackChangesIcon sx={{ color: "black" }} />
                                <Typography sx={{ color: "#7b809a", fontSize: size.sm }} variant="subtitle2">
                                    {selectedCourse.objective}
                                </Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="flex-end">
                            <Chip color="warning" onClick={enrollHandler} label="ENROLL" />
                            </Stack>
                            </Stack> : <Stack direction="column"  justifyContent="center">
                            <Typography variant="h4">
                                NO COURSE SELECTED
                            </Typography>

                            <img style={{ width:'100%',height:'auto', objectFit: "cover" }} src={sadIllustration} />
                            
                            </Stack>}
                        </Card>
                        </Grid>
                    </Grid>
                </Box>
              </CustomTabPanel>
              
            </Box>
          </Grid>
        
      </Box>

      <Footer />
    </DashboardLayout>
  );
}

export default Applications;
