/* eslint-disable camelcase */
/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, { useState, useEffect, useReducer } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// @mui material components
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import { Avatar, Backdrop, Box, Chip, CircularProgress, Typography } from "@mui/material";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import ClearIcon from "@mui/icons-material/Clear";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import TodayIcon from "@mui/icons-material/Today";
import WcIcon from "@mui/icons-material/Wc";
import CakeIcon from "@mui/icons-material/Cake";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import typography from "assets/theme/base/typography";

import UserDetailForm1 from "components/UserDetailForm1";

// react-router-dom components
import { useNavigate, useParams } from "react-router-dom";

// Notistack
import { useSnackbar } from "notistack";

// icons
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

// API
import { generalPostApi } from "services";
import SubscriberUserForm from "components/SubscriberUserForm";
import { fDateTime } from "utilities";
import UserDetailForm2 from "components/UserDetailForm2";
import UserDetailForm3 from "components/UserDetailForm3";
import UserDetailForm4 from "components/UserDetailForm4";
import { LoadingButton } from "@mui/lab";
import { multimediaApi } from "services";

function UserProfile() {
  const { size } = typography;
  const userData = JSON.parse(sessionStorage.getItem("userData"));

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(userData);
  // const [reducerState, dispatch] = useReducer(reducer, initialState);

  const [details, setDetails] = useState();
  const [educationDetails, setEducationDetails] = useState();
  const [step, setStep] = useState(118);
  const [dep, setDep] = useState(1);

  const changeHandler = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const addHandler = (e) => {
    e.preventDefault();
    // let newDetails = [educationDetails];
    setDetails({ educations: educationDetails });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    let data1 = { user_id: userData.user_id, ...details };
    if (step === 127) {
      data1 = { user_id: userData.user_id, educations: [{ is_new: true, ...details }] };
    }
    if (step === 128) {
      data1 = { user_id: userData.user_id, works: [{ is_new: true, ...details }] };
    }

    setLoading(true);
    try {
      const {
        data: { code, user, msg },
      } = await generalPostApi({
        code: step,
        api: 100,
        data: data1,
      });
      if (code === 200) {
        setLoading(false);
        setUser(user);

        enqueueSnackbar("Details update sucsessfully");
      } else {
        setLoading(false);
        enqueueSnackbar("Something  went wrong 1", { variant: "error" });
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar("Something  went wrong 2", { variant: "error" });
    }
  };

  const handleNext = () => {
    if (step === 118) {
      setStep(125);
      setDetails(null);
    }
    if (step === 125) {
      setStep(127);
      setDetails(null);
    }

    if (step === 127) {
      setStep(128);
      setDetails(null);
    }
    if (step === 128) {
      setStep(400);
      setDetails(null);
    }
    if (step === 400) {
      setStep(118);
      setDetails(null);
    }

    // if (step === 127) {
    //   setStep(118);
    //   setDetails(null);
    // }
  };

  const pullUsersHandler = async () => {
    setLoading(true);
    try {
      const {
        data: { code, data, msg },
      } = await generalPostApi({
        code: 111,
        api: 100,
        data: { user_id: userData.user_id },
      });
      if (code === 200) {
        setLoading(false);

        setUser(data.user);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getCvHandler = async () => {
    setLoading(true);
    try {
      const {
        data: { code, link, msg },
      } = await generalPostApi({
        code: 126,
        api: 100,
        data: { user_id: userData.user_id },
      });
      if (code === 200) {
        setLoading(false);
        window.location.href = link;
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const validateAdmin = (userId) => {
    if (userId === undefined || userId == null) {
      navigate("authentication/sign-in");
      return false;
    } else {
      return true;
    }
  };

  //image functionalities
  const [imageSource, setImageSource] = useState([]);
  const [imageSource2, setImageSource2] = useState([]);

  const [imageSourcetwo, setImageSourcetwo] = useState([]);
  const [imageSource2two, setImageSource2two] = useState([]);

  var fileObj = [];
  var fileArray = [];
  let fileObjtwo = [];
  let fileArraytwo = [];

  const [is_File, set_is_file] = useState(false);

  const imageHandler = async (e) => {
    setImageSource2(e.target.files);
    set_is_file(false);

    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]));
    }
    setImageSource({ file: fileArray });
  };

  const imageDelete = (key) => {
    set_is_file(false);

    const imageArray = [...imageSource.file];
    imageArray.splice(key, 1);
    setImageSource({ file: imageArray });
    setImageSource2([]);
  };

  const uploadDocument = async (code) => {
    console.log("ASDDDDDDDDDDDDDDDDDDDDDDDDDDDDD");
    // e.preventDefault();
    setLoading(true);
    if (code === 400) {
      if (!details.title) {
        console.log("SSSSSSSSSSSSSS");
        setLoading(false);
        enqueueSnackbar("Please add the title", { variant: "error" });
        setLoading(false);
        return;
      }
    }
    if (imageSource2.length === 0) {
      setLoading(false);
      enqueueSnackbar("Please select document", { variant: "error" });
    } else {
      var data1 = new FormData();
      data1.append("user_id", userData.user_id);
      data1.append("code", code);
      for (let i = 0; i < imageSource2.length; i++) {
        if (code === 400) {
          data1.append(`attachment`, imageSource2[i]);
          data1.append(`title`, details.title);
        } else {
          data1.append(`profile`, imageSource2[i]);
        }
      }

      try {
        console.log("aaaaaaaaaaaa");
        const {
          data: { code },
        } = await multimediaApi(data1);
        if (code === 200) {
          setLoading(false);
          enqueueSnackbar(" updated succesfully!", { variant: "success" });

          setImageSource2([]);
          setImageSource([]);
          setDep(dep + 3);
        } else {
          setLoading(false);
          enqueueSnackbar("Something  went wrong", { variant: "error" });
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const imageHandlerAndUpload = async (e) => {
    setLoading(true);
    console.log("ssssssssssssssssssssssss", e);

    setImageSource2(e.target.files);
    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]));
    }

    setTimeout(function () {
      console.log("image1", imageSource2);
      uploadDocument(410);
    }, 5000);
  };
  //  states

  useEffect(() => {
    if (validateAdmin(userData)) {
      pullUsersHandler();
    } else {
      navigate("/authentication/sign-in");
    }
  }, [dep]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box sx={{ mt: 2, background: "white", p: 2 }}>
        <Stack sx={{ mt: 2 }} spacing={2} direction="row">
          <Chip
            sx={{ borderRadius: "5px", background: "#ffa500", height: "100%", px: 0 }}
            label="Location"
          />
          <Typography variant="subtitle2" sx={{ color: "#7b809a", fontSize: size.sm }}>
            {user?.region} ,
          </Typography>
          <Typography variant="subtitle2" sx={{ color: "#7b809a", fontSize: size.sm }}>
            {user?.country} ,
          </Typography>
        </Stack>
        <Typography variant="h4" sx={{ mt: 2 }}>
          <b>{user?.full_name}</b>
        </Typography>
        <Stack justifyContent="space-between" sx={{ mt: 2 }} direction="row" spacing={2}>
          <Stack sx={{ mt: 2 }} direction="row" spacing={2}>
            <Stack spacing={1} direction="row">
              <TodayIcon />
              <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                {user?.registered}
              </Typography>
            </Stack>

            <Stack spacing={1} direction="row">
              <WcIcon />
              <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                {user?.gender}
              </Typography>
            </Stack>

            <Stack spacing={1} direction="row">
              <CakeIcon />
              <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                {user?.age}
              </Typography>
            </Stack>
          </Stack>

          <Stack>
            <LoadingButton
              onClick={getCvHandler}
              variant="outlined"
              sx={{ borderRadius: 2, background: "green" }}
            >
              GET CV
            </LoadingButton>
          </Stack>
        </Stack>
        <Divider sx={{ backgroundColor: "green" }} variant="fullWidth" />

        <Grid sx={{ mt: 2 }} container>
          <Grid item xs={12} md={6}>
            <Stack spacing={2} direction="column">
              <Stack justifyContent="center" direction="row">
                <input
                  onChange={imageHandlerAndUpload}
                  accept="images/*"
                  style={{ display: "none" }}
                  id="icon-button-file-2"
                  type="file"
                />
                <label htmlFor="icon-button-file-2">
                  <Avatar
                    sx={{ border: "1px solid green", width: 86, height: 86 }}
                    src={user.photo_url}
                  />
                </label>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Stack spacing={1} alignItems="center" direction="row">
                  <LocalPhoneIcon sx={{ color: "#7b809a" }} />
                  <Typography sx={{ color: "#7b809a", fontSize: size.sm }} variant="subtitle2">
                    Tel
                  </Typography>
                </Stack>
                <Typography variant="subtitle2">+255766342349</Typography>
              </Stack>

              <Stack direction="row" justifyContent="space-between">
                <Stack spacing={1} alignItems="center" direction="row">
                  <MailOutlineIcon sx={{ color: "#7b809a" }} />
                  <Typography sx={{ color: "#7b809a", fontSize: size.sm }} variant="subtitle2">
                    Email
                  </Typography>
                </Stack>
                <Typography variant="subtitle2">+255766342349</Typography>
              </Stack>

              <Stack direction="row" justifyContent="space-between">
                <Stack spacing={1} alignItems="center" direction="row">
                  <SellOutlinedIcon sx={{ color: "#7b809a" }} />
                  <Typography sx={{ color: "#7b809a", fontSize: size.sm }} variant="subtitle2">
                    Status
                  </Typography>
                </Stack>
                <Typography variant="subtitle2">Active</Typography>
              </Stack>
              <Stack spacing={1} direction="column">
                <Stack spacing={1} alignItems="center" direction="row">
                  <InfoOutlinedIcon sx={{ color: "#7b809a" }} />
                  <Typography sx={{ color: "#7b809a", fontSize: size.sm }} variant="subtitle2">
                    Bio
                  </Typography>
                </Stack>
                <Box sx={{ borderRadius: "5px", p: 2, background: "#f1f2f4" }}>
                  <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                    {user?.bio}
                  </Typography>
                </Box>
              </Stack>
            </Stack>
            <Stack spacing={2} sx={{ px: 1 }} direction="column">
              <Stack direction="row">
                <Stack spacing={1} alignItems="center" direction="row">
                  <Typography variant="subtitle2"> Nationality: </Typography>
                  <Typography sx={{ color: "#7b809a", fontSize: size.sm }} variant="subtitle2">
                    {user?.nationality}
                  </Typography>
                </Stack>
              </Stack>

              <Stack direction="row">
                <Stack spacing={1} alignItems="center" direction="row">
                  <Typography variant="subtitle2"> Progress: </Typography>
                  <Typography sx={{ color: "#7b809a", fontSize: size.sm }} variant="subtitle2">
                    0%
                  </Typography>
                </Stack>
              </Stack>

              <Stack direction="row">
                <Stack spacing={1} sx={{ width: "100%" }} direction="column">
                  <Typography variant="subtitle2"> Skills: </Typography>
                  <Box
                    sx={{
                      minHeight: "100px",
                      width: "100%",
                      borderRadius: "5px",
                      p: 2,
                      background: "#f1f2f4",
                    }}
                  >
                    <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                      {user?.skills}
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid sx={{ display: "flex", alignContent: "center" }} item xs={12} md={1}>
            <Divider orientation="vertical" />
          </Grid>
          <Grid sx={{ borderRadius: 2 }} item xs={12} md={5}>
            <Stack sx={{ px: 3, width: "100%" }} justifyContent="flex-end" direction="row">
              <Button style={{ color: "green" }} variant="text" onClick={handleNext}>
                {step === 400 ? "FINISH" : "NEXT"}
              </Button>
            </Stack>

            {step === 118 ? (
              <UserDetailForm1
                details={details}
                handleChange={changeHandler}
                loading={loading}
                submitHandler={submitHandler}
              />
            ) : null}

            {step === 125 ? (
              <UserDetailForm2
                details={details}
                handleChange={changeHandler}
                loading={loading}
                submitHandler={submitHandler}
              />
            ) : null}

            {step === 127 ? (
              <Stack direction="column">
                <UserDetailForm3
                  details={details}
                  handleChange={changeHandler}
                  loading={loading}
                  submitHandler={submitHandler}
                />
                <Divider />
                {/* <Button sx={{ color: "green" }} endIcon={<AddIcon />}>
                  add More
                </Button> */}
              </Stack>
            ) : null}

            {step === 128 ? (
              <Stack direction="column">
                <UserDetailForm4
                  details={details}
                  handleChange={changeHandler}
                  loading={loading}
                  submitHandler={submitHandler}
                />
                <Divider />
                {/* <Button sx={{ color: "green" }} endIcon={<AddIcon />}>
                  add More
                </Button> */}
              </Stack>
            ) : null}

            {step === 400 ? (
              <Stack sx={{ px: 3, width: "100%" }} direction="row">
                <MDBox sx={{ width: "100%" }} mb={2}>
                  <MDInput
                    fullWidth
                    value={details?.title}
                    type="string"
                    name="title"
                    autoFocus
                    required
                    InputLabelProps={{ shrink: details?.title }}
                    onChange={changeHandler}
                    label="TITLE"
                  />
                  <div>
                    <div>
                      <div>
                        <input
                          onChange={imageHandler}
                          accept="pdf"
                          style={{ display: "none" }}
                          id="icon-button-file"
                          type="file"
                        />
                      </div>

                      <Stack
                        justifyContent="center"
                        sx={(theme) => ({
                          position: "relative",
                          borderRadius: 2,
                          height: "50px",
                          width: "100%",
                          mt: 2,
                          border: "1px solid grey",
                          [theme.breakpoints.down("md")]: {
                            width: "30%",
                          },
                        })}
                      >
                        <Stack
                          spacing={1}
                          justifyContent="center"
                          direction="row"
                          // sx={{ left: "20px", top: "10%", position: "absolute" }}
                        >
                          <label htmlFor="icon-button-file">
                            <IconButton
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                            >
                              <CropOriginalIcon style={{ color: "red" }} />
                            </IconButton>
                          </label>
                        </Stack>
                      </Stack>
                    </div>

                    <Grid container>
                      {(imageSource.file || []).map((url, i) => (
                        <Grid key={i} item md={6} sm={6} xs={6}>
                          <div>
                            <ClearIcon onClick={() => imageDelete(i)} />
                            {/* <img src={url} alt="..." width="97%" height="auto" /> */}
                            <Stack
                              alignItems="center"
                              justifyContent="center"
                              sx={{
                                borderRadius: 2,
                                background: "#f1f2f4",
                                width: "100%",
                                height: "50px",
                              }}
                            >
                              <Typography variant="caption">
                                {details?.title ?? "Attachment 1"}
                              </Typography>
                            </Stack>
                          </div>
                        </Grid>
                      ))}
                    </Grid>

                    <LoadingButton
                      loading={loading}
                      sx={{
                        width: "100%",
                        mt: 5,
                        borderRadius: 1,
                        backgroundColor: "green",
                        color: "white",
                      }}
                      variant="contained"
                      onClick={() => uploadDocument(400)}
                    >
                      Save
                    </LoadingButton>
                  </div>
                </MDBox>
              </Stack>
            ) : null}
          </Grid>
        </Grid>
      </Box>

      <Backdrop
        sx={{ color: "#4CAF50", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Footer />
    </DashboardLayout>
  );
}

export default UserProfile;
