import React, { useState } from "react";
import { SketchPicker, BlockPicker, SwatchesPicker } from "react-color";

import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { Button, Grid, Stack, Typography } from "@mui/material";
import Radio from "@mui/material/Radio";
import InputAdornment from "@mui/material/InputAdornment";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

function UserDetailForm4({
  loading,
  handleChangeFontSize,
  background,
  handleChangeComplete,
  details,
  handleChange,
  submitHandler,
}) {
  const [selectedValue, setSelectedValue] = React.useState("a");

  const [fonts, setFonts] = useState([
    { value: "h5" },
    { value: "subtitle1" },
    { value: "subtitle2" },
    { value: "h4" },
  ]);
  const handleChangeRadio = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <form onSubmit={submitHandler}>
      <MDBox pt={4} pb={3} px={3}>
        <Typography sx={{ mb: 2 }} align="center" variant="body1">
          Work Information
        </Typography>
        <MDBox>
          <MDBox mb={2}>
            <MDInput
              value={details?.company}
              type="string"
              name="company"
              autoFocus
              required
              InputLabelProps={{ shrink: details?.company }}
              onChange={handleChange}
              label="Company name"
              fullWidth
            />
          </MDBox>

          <MDBox mb={2}>
            <MDInput
              value={details?.position}
              type="string"
              name="position"
              required
              InputLabelProps={{ shrink: details?.position }}
              onChange={handleChange}
              label="Position"
              fullWidth
            />
          </MDBox>

          <MDBox mb={2}>
            <MDInput
              value={details?.role}
              InputLabelProps={{ shrink: details?.role }}
              type="string"
              name="role"
              required
              onChange={handleChange}
              label="Role"
              fullWidth
            />
          </MDBox>

          <MDBox mb={2}>
            <Stack spacing={3} direction="row">
              <MDInput
                value={details?.start_year}
                InputLabelProps={{ shrink: details?.start_year }}
                type="date"
                name="start_year"
                required
                onChange={handleChange}
                label="Start Year"
                fullWidth
              />

              <MDInput
                value={details?.end_year}
                InputLabelProps={{ shrink: details?.end_year }}
                type="date"
                name="end_year"
                required
                onChange={handleChange}
                label="End Year"
                fullWidth
              />
            </Stack>
          </MDBox>

          <LoadingButton
            loading={loading}
            style={{ borderRadius: 2, backgroundColor: "green", color: "white" }}
            variant="contained"
            type="submit"
          >
            Save
          </LoadingButton>
        </MDBox>
      </MDBox>
    </form>
  );
}

export default UserDetailForm4;
UserDetailForm4.propTypes = {
  submitHandler: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  details: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};
