import React, { useState } from "react";
import { SketchPicker, BlockPicker, SwatchesPicker } from "react-color";

import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { Button, Grid, TextareaAutosize } from "@mui/material";
import Radio from "@mui/material/Radio";
import InputAdornment from "@mui/material/InputAdornment";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

function UserDetailForm2({
  loading,
  handleChangeFontSize,
  background,
  handleChangeComplete,
  details,
  handleChange,
  submitHandler,
}) {
  const [selectedValue, setSelectedValue] = React.useState("a");

  const [fonts, setFonts] = useState([
    { value: "h5" },
    { value: "subtitle1" },
    { value: "subtitle2" },
    { value: "h4" },
  ]);
  const handleChangeRadio = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <form onSubmit={submitHandler}>
      <MDBox pt={4} pb={3} px={3}>
        <MDBox>
          <MDBox mb={2}>
            <TextareaAutosize
              minRows={5}
              style={{
                border: "1px solid black",
                color: "#495057",
                padding: "10px",
                borderRadius: "8px",
                width: "100%",
                height: "200px",
              }}
              value={details?.bio}
              onChange={handleChange}
              type="string"
              name="bio"
              required
              placeholder="Bio"
              InputLabelProps={{ shrink: true }}
              label="Bio"
              fullWidth
            />
          </MDBox>

          <MDBox mb={2}>
            <TextareaAutosize
              onChange={handleChange}
              minRows={5}
              style={{
                border: "1px solid black",
                color: "#495057",
                padding: "10px",
                borderRadius: "8px",
                width: "100%",
                height: "200px",
              }}
              value={details?.skills}
              type="string"
              name="skills"
              required
              placeholder="Skills"
              InputLabelProps={{ shrink: true }}
              label="Skills"
              fullWidth
            />
          </MDBox>

          <LoadingButton
            loading={loading}
            style={{ borderRadius: 2, backgroundColor: "green", color: "white" }}
            variant="contained"
            type="submit"
          >
            Save
          </LoadingButton>
        </MDBox>
      </MDBox>
    </form>
  );
}

export default UserDetailForm2;
UserDetailForm2.propTypes = {
  submitHandler: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  details: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};
