import React from "react";

// @mui material components
import { Grid, Avatar, Card, Box, Typography, Stack, Divider, Chip } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import typography from "assets/theme/base/typography";

// icons
// import ApartmentTwoToneIcon from "@mui/icons-material/ApartmentTwoTone";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";

import { stringToColor } from "utilities";
import CourseImage from "../assets/images/coursePic4.png";

function CourseCard({ viewHandler, isSelected, title, description }) {
  const { size } = typography;
  return (
    <Card
      sx={{
        p: 0.8,
        borderRadius: "10px",
        border: "2px solid #E9EEF4",
        boxShadow: "none",
        width: "100%",
        height: "110px",
        overflow: "hidden",
      }}
    >
      <Grid container>
        <Grid item xs={4} md={4}>
          <div>
            <img style={{ width: "100%", height: "auto", objectFit: "cover" }} src={CourseImage} />
          </div>
        </Grid>
        <Grid item xs={8} md={8}>
          <Stack direction="row">
            <Stack
              sx={{ px: 1, width: "100%" }}
              spacing={2}
              direction="row"
              justifyContent="space-between"
            >
              <Stack direction="column">
                <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                  <b>{title}</b>
                </Typography>

                <Typography
                  variant="subtitle2"
                  paragraph
                  sx={{
                    fontSize: size.sm,
                    color: "#7b809a",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    height: "50px",
                    //  display: "-webkit-box",
                    webkitLineClamp: 1 /* number of lines to show */,
                    //  webkitBoxOrient: "vertical" ,
                  }}
                >
                  {description}
                </Typography>
              </Stack>
              {isSelected ? (
                <Chip
                  avatar={<Avatar sx={{ backgroundColor: "green" }}>v</Avatar>}
                  onClick={viewHandler}
                  variant={"filed"}
                  sx={{ p: 0 }}
                  label="View"
                />
              ) : (
                <Chip onClick={viewHandler} variant={"outlined"} sx={{ p: 0 }} label="View" />
              )}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
}

export default CourseCard;
