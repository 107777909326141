/* eslint-disable camelcase */
/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import { Box, Chip, Typography, Tab, Tabs } from "@mui/material";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import ClearIcon from "@mui/icons-material/Clear";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import TodayIcon from "@mui/icons-material/Today";
import WcIcon from "@mui/icons-material/Wc";
import CakeIcon from "@mui/icons-material/Cake";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PushPinIcon from "@mui/icons-material/PushPin";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import typography from "assets/theme/base/typography";

// react-router-dom components
import { useNavigate, useParams } from "react-router-dom";

// Notistack
import { useSnackbar } from "notistack";

// icons
import EditIcon from "@mui/icons-material/Edit";

// API
import { generalPostApi } from "services";
import SubscriberUserForm from "components/SubscriberUserForm";
import { fDateTime } from "utilities";
import { stringToColor } from "utilities";
import JobComponent from "components/JobComponent";
import CompanyComponent from "components/CompanyComponent";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ApplicationProfile() {
  const { size } = typography;
  const userData = JSON.parse(sessionStorage.getItem("userData"));

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  console.log("ss", id);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const pullUsersHandler = async () => {
    setLoading(true);
    try {
      const {
        data: { code, applications, msg },
      } = await generalPostApi({
        code: 116,
        api: 120,
        data: { page_number: 1, operator_id: userData.operator_id },
      });
      if (code === 200) {
        setLoading(false);

        setUser(applications.filter((item) => item.application_id === id)[0]);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const validateAdmin = (userId) => {
    if (userId === undefined || userId == null) {
      navigate("authentication/sign-in");
      return false;
    } else {
      return true;
    }
  };

  //  states

  useEffect(() => {
    if (validateAdmin(userData)) {
      pullUsersHandler();
    } else {
      navigate("/authentication/sign-in");
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Box sx={{ mt: 2, background: "white", p: 2 }}>
            <Stack direction="column">
              <Stack spacing={1} alignItems="center" direction="column" justifyContent="center">
                <img
                  style={{ borderRadius: "50%", height: "10vw", width: "10vw" }}
                  src={user?.user?.photo_url}
                />
                <Typography variant="subtitle2">
                  <b>{user?.user?.full_name}</b>{" "}
                </Typography>

                <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                  Applied {fDateTime(user?.registered)}
                </Typography>
                <Stack direction="row">
                  <IconButton href={`mailto:${user?.user?.email}`}>
                    <MailOutlineIcon />
                  </IconButton>
                  <IconButton href={`tel:${user?.user?.phone_number}`}>
                    <LocalPhoneIcon />
                  </IconButton>
                </Stack>
              </Stack>
              <Divider sx={{ backgroundColor: "green" }} />
              <Stack direction="row">
                <Chip
                  variant="outlined"
                  sx={{
                    background: "rgba(76, 175, 80, 0.2)",
                    color: "green",
                    height: "100%",
                    px: 0,
                  }}
                  label={user?.status}
                />
              </Stack>
              <Divider sx={{ backgroundColor: "green" }} />
              <Stack direction="column">
                <Typography x={{ fontSize: size.sm }} variant="subtitle2">
                  Applied Job
                </Typography>
                <Box sx={{ borderRadius: "5px", p: 2, background: "#f1f2f4" }}>
                  <Stack direction="column">
                    <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                      <b>{user?.job?.title}</b>
                    </Typography>
                    <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                      {user?.job?.job_type} <b>.</b> {user?.job?.location}
                    </Typography>
                  </Stack>
                </Box>
              </Stack>

              <Stack>
                <Box sx={{ mt: 2, borderRadius: "5px", p: 2, border: " 1px solid #f1f2f4" }}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                      Job type
                    </Typography>
                    <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                      <b>{user?.job?.job_type}</b>
                    </Typography>
                  </Stack>
                </Box>
              </Stack>

              <Divider sx={{ backgroundColor: "green" }} />
              <Stack direction="column" spacing={2}>
                <Typography variant="subtitle2">
                  <b>Contant details</b>
                </Typography>

                <Stack spacing={2} alignItems="center" direction="row">
                  <LocalPhoneIcon sx={{ color: "#7b809a" }} />
                  <Stack direction="column">
                    <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                      Phone
                    </Typography>
                    <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                      {user?.user?.phone_number}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack spacing={2} alignItems="center" direction="row">
                  <MailOutlineIcon sx={{ color: "#7b809a" }} />
                  <Stack direction="column">
                    <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                      Email
                    </Typography>
                    <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                      {user?.user?.email}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack spacing={2} alignItems="center" direction="row">
                  <PushPinIcon sx={{ color: "#7b809a" }} />
                  <Stack direction="column">
                    <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                      Address
                    </Typography>
                    {/* <Typography sx={{ color: "#7b809a", fontSize: size.sm }} variant="subtitle2">
                      {user?.user?.region} , {user?.user?.country}
                    </Typography> */}

                    <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                      Mwenge , Dar es salaam
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box sx={{ mt: 2, background: "white", p: 2 }}>
            <Box sx={{ px: 3, width: "100%" }}>
              <Box sx={{ borderColor: "divider" }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab
                    label={
                      <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                        User profile
                      </Typography>
                    }
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={
                      <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                        Job
                      </Typography>
                    }
                    {...a11yProps(1)}
                  />

                  <Tab
                    label={
                      <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                        Company
                      </Typography>
                    }
                    {...a11yProps(2)}
                  />

                  <Tab
                    label={
                      <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                        Attachement
                      </Typography>
                    }
                    {...a11yProps(3)}
                  />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Box sx={{ borderRadius: "5px", p: 2 }}>
                  <Typography sx={{ mb: 2 }} variant="subtitle2">
                    <b>Personal Details</b>
                  </Typography>
                  <Grid container>
                    <Grid item xs={6} md={6}>
                      <Stack spacing={2} direction="column">
                        <Stack direction="row">
                          <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                            <b>Full name:</b> {user?.user?.full_name}
                          </Typography>
                        </Stack>

                        <Stack direction="row">
                          <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                            <b>Age:</b> {user?.user?.age}
                          </Typography>
                        </Stack>

                        <Stack direction="row">
                          <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                            <b>Gender:</b> {user?.user?.gender}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Stack spacing={2} direction="column">
                        <Stack direction="row">
                          <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                            <b>Nationality:</b> {user?.user?.nationality}
                          </Typography>
                        </Stack>

                        <Stack direction="row">
                          <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                            <b>DOB:</b> {user?.user?.date_of_birth}
                          </Typography>
                        </Stack>

                        <Stack direction="row">
                          <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                            <b>location:</b> {user?.user?.street}, {user?.user?.region} ,{" "}
                            {user?.user?.country}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>

                  <Stack></Stack>
                </Box>
                <Divider sx={{ background: "green" }} />
                <Stack sx={{ px: 2 }}>
                  <Typography sx={{ mb: 2 }} variant="subtitle2">
                    <b>Bio</b>
                  </Typography>
                  <Box sx={{ borderRadius: "5px", p: 2, background: "#f1f2f4" }}>
                    <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                      {user?.user?.bio}
                    </Typography>
                  </Box>
                </Stack>
                <Divider sx={{ background: "green" }} />

                <Stack sx={{ px: 2 }}>
                  <Typography sx={{ mb: 2 }} variant="subtitle2">
                    <b>Experience</b>
                  </Typography>
                  <Stack diection="column" spacing={1} sx={{ borderRadius: "5px" }}>
                    {user?.user?.works.length > 0 ? (
                      user?.user?.works.map((item) => {
                        return (
                          <Stack direction="column">
                            <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                              <b>
                                {item.position} at {item.company}
                              </b>
                            </Typography>
                            <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                              {item.start_year} - {item.end_year}
                            </Typography>
                          </Stack>
                        );
                      })
                    ) : (
                      <div>Not available</div>
                    )}
                  </Stack>
                </Stack>

                <Divider sx={{ background: "green" }} />

                <Stack sx={{ px: 2 }}>
                  <Typography sx={{ mb: 2 }} variant="subtitle2">
                    <b>Education</b>
                  </Typography>
                  <Stack diection="column" spacing={1} sx={{ borderRadius: "5px" }}>
                    {user?.user?.educations.length > 0 ? (
                      user?.user?.educations.map((item) => {
                        return (
                          <Stack direction="column">
                            <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                              <b>
                                {item.education_level} at {item.institute_name}
                              </b>
                            </Typography>
                            <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                              {item.start_year} - {item.finish_year}
                            </Typography>
                          </Stack>
                        );
                      })
                    ) : (
                      <div>Not available</div>
                    )}
                  </Stack>
                </Stack>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                {/* <Box sx={{ borderRadius: "5px", p: 2, background: "rgba(76, 175, 80, 0.2)" }}> */}
                <JobComponent details={user?.job} />
                {/* </Box> */}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                {/* <Box sx={{ borderRadius: "5px", p: 2, background: "rgba(76, 175, 80, 0.2)" }}> */}
                <CompanyComponent details={user?.company} />
                {/* </Box> */}
              </CustomTabPanel>

              <CustomTabPanel value={value} index={3}>
                {user?.user?.attachments.length > 0 ? (
                  user?.user?.attachments.map((item) => {
                    return (
                      <Stack
                        sx={{ borderRadius: "5px", mb: 1, background: "#FAF8F4", p: 1 }}
                        spacing={0}
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                          {item.title}
                        </Typography>
                        <Stack spac direction="column">
                          <IconButton size="small" href={item.url}>
                            <InsertLinkIcon />{" "}
                          </IconButton>
                          <Typography
                            sx={{ fontSize: size.sm, color: "#7b809a" }}
                            variant="subtitle2"
                          >
                            {fDateTime(item.registered)}
                          </Typography>
                        </Stack>
                      </Stack>
                    );
                  })
                ) : (
                  <div> No AttachementS</div>
                )}
              </CustomTabPanel>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default ApplicationProfile;
